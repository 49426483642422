import PropTypes from "prop-types";
import React, {useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {validEmail} from "../helpers/validations";
import {createContact, validateReCaptcha} from '../api';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const mailingListId = process.env.GATSBY_ACTIVE_CAMPAIGN_729_SOLUTIONS_MAILING_LIST_ID;
const subscriberBlogFormTagId = process.env.GATSBY_ACTIVE_CAMPAIGN_SUBSCRIBER_BLOG_FORM_TAG

const SubscribeForm = ({buttonText, onSubmitSuccessful}) => {
  const [validated, setValidated] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formInfo, setFormInfo] = useState({
    firstname: "",
    email: {value: "", valid: false},
  });

  const { executeRecaptcha } = useGoogleReCaptcha();


  const isValid = (form) => {
    const {email} = formInfo;
    return form.checkValidity() && email.valid;
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (isValid(form)) {
      const {firstname: name, email} = formInfo;
      try {
        const reCaptchaToken = await executeRecaptcha();
        await validateReCaptcha(reCaptchaToken);
        await createContact({contact: {name, email: email.value}, mailingListId, tags: [subscriberBlogFormTagId]})
        setShowSuccessMessage(true)
      } catch (error) {
        setShowErrorMessage(true)
      }

    }
    setValidated(true);
  };


  if (showSuccessMessage) {
    return (
      <>
        <h4>Subscribe</h4>
        <p>Thank you for subscribing! Keep an eye on your inbox for news and announcements.</p>
      </>
    )
  } else if (showErrorMessage) {
    return (
      <Alert variant="danger">
        Something went wrong, please try again later!
      </Alert>)
  } else {
    return (
      <>
        <h4>Subscribe</h4>
        <p>
          Want to keep up with what we are doing? Hear about what’s coming next straight to your inbox!
        </p>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="mb-6"
        >
          <Form.Group className="required">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              required
              isInvalid={validated && formInfo.firstname.length === 0}
              isValid={validated && formInfo.firstname.length > 0}
              onChange={(event) => {
                setFormInfo({
                  ...formInfo,
                  firstname: event.target.value,
                });
              }}
            />
            {formInfo.firstname.length === 0 ? (
              <div className="mt-2 invalid-feedback">
                Please complete this required field.
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="required">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mail"
              required
              isInvalid={
                validated &&
                (!formInfo.email.valid || formInfo.email.value.length === 0)
              }
              isValid={
                validated && formInfo.email.valid && formInfo.email.value.length > 0
              }
              onChange={(event) => {
                setFormInfo({
                  ...formInfo,
                  email: {
                    value: event.target.value,
                    valid: validEmail(event.target.value),
                  },
                });
              }}
            />
            {!formInfo.email.valid ? (
              <div className="mt-2 invalid-feedback">
                Please enter a valid email.
              </div>
            ) : null}
            {formInfo.email.value.length === 0 ? (
              <div className="mt-2 invalid-feedback">
                Please complete this required field.
              </div>
            ) : null}
          </Form.Group>
          <Button type="submit">Sign up</Button>
        </Form>
      </>
    );
  }
};

SubscribeForm.defaultProps = {
  buttonText: "Send My Message Now!",
};

SubscribeForm.propTypes = {
  buttonText: PropTypes.string,
};
export default SubscribeForm;
