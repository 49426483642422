import { graphql, Link } from "gatsby";
import React from "react";
import BlogFeedEntry from "../components/blog-feed-entry";
import BlogSidebar from "../components/blog-sidebar";
import Layout from "../components/layout";
import Seo from "../components/seo"

const PageLinks = ({ rootPath, currentPage, hasNext, hasPrevious }) => {
    return (
        <>
            <div className="text-left">
            {hasNext && (
                <Link className="text-left" to={`/${rootPath}/page/${currentPage + 1}`}>« Older Entries</Link>
            )}
            </div>
            <div className="text-right">
            {hasPrevious && (
                <Link className="text-right" to={ currentPage === 2 ? `/${rootPath}` : `/${rootPath}/page/${currentPage - 1}`}>Next Entries »</Link>
            )}
            </div>
        </>
    );
};

const Home = ({ data, pageContext }) => {
  const pageInfo = data.allWpPost.pageInfo;
  const { rootPath, title } = pageContext;
  return (
    <Layout>
      <Seo
        title={title || 'Our Blog' }
      />
      <section>
        <div className="container-fluid pt-6">
          <div className="row">
            <div className="col-xl-10">
              <div className="row px-xl-6 mb-5">
                <div className="col pl-lg-0">
                  <h1>{title || 'Our Blog' }</h1>
                  <p>
                    The 729 Solutions Blog is a place for us to showcase our
                    unique approach to engineering, UI/UX design, our awesome
                    partnerships with other companies and the voices of our
                    growing team.
                  </p>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-2 mb-3 pl-xl-6">
                {data.allWpPost.nodes.map((node) => (
                  <div
                    key={node.slug}
                    className="col pr-xl-6 mb-xl-6 mb-4 pl-lg-0"
                  >
                    <BlogFeedEntry post={node} />
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col pl-lg-6">
                  <PageLinks
                    rootPath={rootPath}
                    currentPage={pageInfo.currentPage}
                    hasNext={pageInfo.hasNextPage}
                    hasPrevious={pageInfo.hasPreviousPage}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($perPage: Int!, $skip: Int!, $searchFilter: WpPostFilterInput = {}) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $perPage
      skip: $skip
      filter: $searchFilter
    ) {
      nodes {
        title
        excerpt
        date(formatString: "MMM D, YYYY")
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
            caption
          }
        }
        author { node { name slug } }
        categories {
          nodes {
            slug
            name
          }
        }
      }
      pageInfo {
        currentPage
        hasPreviousPage
        hasNextPage
      }
    }
    allWpCategory(sort: { fields: [name] }) {
      nodes {
        name
        link
      }
    }
  }
`;

export default Home;
