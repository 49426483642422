import React from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { Form } from "react-bootstrap";
import SubscribeForm from "./subscribe-form";
import { getPostUrl, getCategoryUrl } from "../helpers/blog-helpers";

const BlogSidebar = () => {
  const data = useStaticQuery(graphql`
    query {  
      allWpCategory(sort: { fields: [name] }, filter: {count: {gt: 0}}) {
        nodes {
          name
          slug
        }
      }
      allWpPost(sort: { fields: [date], order: DESC }, limit: 5) {
        nodes {
          slug
          title
          date(formatString: "MMM D, YYYY")
        }
      }
    }
  `);
  const recentPosts = data.allWpPost.nodes || [];
  const categories = data.allWpCategory || [];
  return (
    <>
      <Form className="mb-6" onSubmit={(e) => {
            e.preventDefault();
            const searchTerm = e.target[0].value;
            if(searchTerm.trim().length > 0) {
              navigate(`/?s=${searchTerm}`);
            }
          }} >
        <Form.Group>
          <Form.Control type="text" placeholder="Search" />
        </Form.Group>
      </Form>


      <SubscribeForm />

      <h4>Categories</h4>
      <Form className="mb-6">
        <Form.Group>
          <Form.Control
            as="select"
            custom
            onChange={(e) => {
              e.preventDefault();
              const categoryLink = e.target.value;
              navigate(categoryLink);
            }}
          >
            <option value="-1">Select Category</option>
            {categories
              ? categories.nodes.map((node) => (
                  <option key={node.name} value={getCategoryUrl(node.slug)}>
                    {node.name}
                  </option>
                ))
              : null}
          </Form.Control>
        </Form.Group>
      </Form>
      <h4>Recent Posts</h4>
      <ul>
        {recentPosts
          ? recentPosts.map((node) => (
              <li key={`${node.slug}_recent`}>
                <Link className="text-primary-light" to={getPostUrl(node.slug)}>
                  {node.title}
                </Link>
                <time
                  dateTime={node.date}
                  className="d-inline-block text-dark"
                >{` - ${node.date}`}</time>
              </li>
            ))
          : null}
      </ul>
    </>
  );
};
export default BlogSidebar;
